const initSlider = ($slider) => {
  const $sliders = $slider

  function buildSlider($container) {
    const $slider = $(".slider", $container);
    const steps = $slider.data("steps");
    const $fromText = $($slider.data("from-text"));
    const $toText = $($slider.data("to-text"));
    const minRange = parseInt($slider.data("min"));
    const maxRange = parseInt($slider.data("max"));

    const $fromValue = $($slider.data("from-value"), $container);
    const $toValue = $($slider.data("to-value"), $container);

    $fromText.text(" " + steps[0]  + " ");
    $toText.text(" " + steps[steps.length - 1]  + "+ ");

    $slider.slider({
      animate: "fast",
      range: true,
      min: minRange,
      max: maxRange,
      step: 1,
      values: [minRange, maxRange],
      change: function(event, ui) {
        const valueFrom = ui.values[0];
        const valueTo = ui.values[1];
        $fromValue.val(steps[valueFrom]);
        $toValue.val(steps[valueTo]);

        $(this).trigger("change");
      },
      slide: function(event, ui) {
        const valueFrom = steps[ui.values[0]];
        let valueTo = steps[ui.values[1]];
        if(steps.indexOf(valueTo) == steps.length -1) {
          valueTo += "+";
        }

        $fromText.text(" " + valueFrom  + " ");
        $toText.text(" " + valueTo  + " ");
      }
    });
  }

  if($sliders.length > 0) {
    $sliders.each(function() {
      buildSlider($(this));
    });
  }
}

export { initSlider };