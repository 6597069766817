import 'select2';

const formatStateEntity = (state) => {
  if (typeof state.id != "number") {
    return $(`<span>${state.text}</span>`);
  }

  const city = state.city != null ? `- <span class="select2-light">${state.city} (${state.department})</span>` : "";
  const $state = $(`<span>${state.text}</span> ${city}`);

  return $state;
};

const formatStateUser = (state) => {
  if (state.function == undefined) {
    return $(`<span>${state.text}</span>`);
  }

  const $state = $(`<span>${state.text}</span> <span class="select2-light">- ${state.function}</span></br>
                    <span class="select2-light">${state.entity}</span>`);

  return $state;
};

const options = ($field, formatState) => {
  return { 
    placeholder: $field.data("placeholder"),
    theme: "classic",
    tags: true,
    language: "fr",
    width: "100%",
    templateResult: formatState,
    minimumInputLength: 3,
    allowClear: true,
    ajax: {
      url: $field.data("url"),
      dataType: 'json',
      data: function (params) {
        return {
          query: params.term,
          page: params.page || 1
        };
      }
    }
  }
};

const initSelect2 = ($searchField) => {
  $searchField.select2({
    theme: "classic",
    language: "fr",
    width: "100%"
  });
};

const initSelect2Users = ($searchField) => {
  $(document).on('select2:open', () => {
    document.querySelector('.select2-search__field').focus();
  });

  $searchField.select2(options($searchField, formatStateUser));
  $searchField.on('select2:select', function (e) {
    const userId = e.params.data.id;
    const baseUrl = window.location.origin;
    if (typeof userId == "number") {
      window.location.href = `${baseUrl}/users/${userId}`;
    } else {
      document.addEventListener('keyup', event => {
        event.preventDefault();
        if (event.key == "Enter") {
          $("#search_button").click();
        }
      })
    }
  });
}

const initSelect2Entities = ($searchField) => {
  $(document).on('select2:open', () => {
    document.querySelector('.select2-search__field').focus();
  });

  $searchField.select2(options($searchField, formatStateEntity));

  $searchField.on("select2:select", function (e) {
    if (typeof e.params.data.id == "number") {
      $(`#${$searchField[0].id}_id`)[0].value = e.params.data.id;
    } else {
      $(`#${$searchField[0].id}_id`)[0].value = "";
    }
  });

  $searchField.on("select2:unselect", function (e) {
    $(`#${$searchField[0].id}_id`)[0].value = "";
  });
}

$.fn.select2.amd.define('select2/i18n/fr',[],function () {
  // French
  return {
    errorLoading: function () {
      return 'Les résultats ne peuvent pas être chargés.';
    },
    inputTooLong: function (args) {
      var overChars = args.input.length - args.maximum;

      return 'Supprimez ' + overChars + ' caractère' +
        ((overChars > 1) ? 's' : '');
    },
    inputTooShort: function (args) {
      var remainingChars = args.minimum - args.input.length;

      return 'Saisissez au moins ' + remainingChars + ' caractère' +
        ((remainingChars > 1) ? 's' : '');
    },
    loadingMore: function () {
      return 'Chargement de résultats supplémentaires…';
    },
    maximumSelected: function (args) {
      return 'Vous pouvez seulement sélectionner ' + args.maximum +
        ' élément' + ((args.maximum > 1) ? 's' : '');
    },
    noResults: function () {
      return 'Aucun résultat trouvé';
    },
    searching: function () {
      return 'Recherche en cours…';
    },
    removeAllItems: function () {
      return 'Supprimer tous les éléments';
    },
    removeItem: function () {
      return 'Supprimer l\'élément';
    }
  };
});

export { initSelect2Entities, initSelect2, initSelect2Users };
