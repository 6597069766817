// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import * as ActiveStorage from "@rails/activestorage";
import "channels"

Rails.start();
ActiveStorage.start();

// External imports
import "foundation-sites";
require("jquery-ui");
require("@nathanvda/cocoon");

// Internal imports
import { initFlatpickr } from "./init_flatpickr";
import { initToggleEndDate, initToggleEhesp } from "./init_toggle_fields";
import { initSelect2Entities, initSelect2, initSelect2Users } from "./init_select2";
import { initSlider } from "./init_slider";
import { initCheckboxTree } from "./init_checkbox-tree";
import { initToggleProfil } from "./init_toggle_profil";
import { initSweetalert } from "./init_sweetalert";

$(document).ready(() => {
  $(document).foundation();

  if ($(".slider-container").length > 0) {
    initSlider($(".slider-container"));
  }

  if ($(".entity-promo").length > 0) {
    initSelect2($(".select-year"));
  }

  if ($("#search_input").length > 0) {
    initSelect2Users($(".search-user-select"));
  }

  if ($(".inscription-container").length > 0) {
    initSelect2($("#user_civility"));

    $(document).on("cocoon:after-insert", function(e, form) {
      if (form[0].className == "form-new-exp") {
        initFlatpickr($(".datepicker", $(form)));
        initToggleEndDate($(form));
        initSelect2Entities($(".entity-autocomplete", $(form)));
      } else {
        initSelect2($(".select-year", $(form)));
        initToggleEhesp($(form));
      }
    });

    document.querySelectorAll(".form-experience-container").forEach((form) => {
      initFlatpickr($(".datepicker", $(form)));
      initToggleEndDate($(form));
      initSelect2Entities($(".entity-autocomplete", $(form)));
    });

    if ($(".form-study-container").length > 0) {
      document.querySelectorAll(".form-study-container").forEach((form) => {
        initSelect2($(".select-year", $(form)));
        initToggleEhesp($(form));
      });
    }
  };

  if ($(".entity-localisations").length > 0) {
    initCheckboxTree();
  }

  if ($(".full-profil-link").length > 0) {
    initToggleProfil($(".full-profil-link"));
  }

  if ($(".users-admin-link").length > 0) {
    initSweetalert();
  }
});
