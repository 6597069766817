const initToggleProfil = ($link) => {

  $link.click(function() {
    const textHide = $(this).data("text-hide");
    const textShow = $(this).data("text-show");

    $(this).toggleClass("rotate");
    $(this).next().toggleClass("hide");

    if ($(this).hasClass("rotate")) {
      this.textContent = textHide;
    } else {
      this.textContent = textShow;
    }
  });
};

export { initToggleProfil };
