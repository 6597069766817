const initCheckboxTree = () => {
  const $regions = $(".checkbox-tree");

  $(".region", $regions).click(function () {
    $(this).siblings("ul").toggleClass("hide");
  });

  $(".input-region", $regions).click(function (event) {
    const state = $(this).is(":checked");
    const $departmentList = $(this).siblings("ul");
    $(".checkbox-tree-item", $departmentList).each(function() {
      $(":checkbox", this).prop("checked", state);
    });
  });

  $(".input-department", $regions).change(function (event) {
    const state = $(this).is(":checked");
    const $parent_region = $(this).closest('ul').closest('li');
    const $ul = $(".departments", $parent_region);
    const checkbox = $(".input-region", $parent_region);

    if (!state) {
      if ($ul.find(':checked').length == 0) {
        checkbox.prop("checked", false);
        checkbox.prop("indeterminate", false);
      } else {
        checkbox.prop("indeterminate", true);
      }
    } else {
      if ($ul.find(':checked').length == $ul.find(':checkbox').length) {
        checkbox.prop("indeterminate", false);
        checkbox.prop("checked", true);
      } else {
        checkbox.prop("indeterminate", true);
      }
    };
  });
};

export { initCheckboxTree };
