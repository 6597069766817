import swal from 'sweetalert';

const initSweetalert = () => {
  $(".users-admin-link").click(function () {
    const relativeLink = $(this).data("relative-link");
    const headText = $(this).data("head-text");
    const bodyText = $(this).data("body-text");
    const cancelBtn = $(this).data("cancel-btn");
    const confirmBtn = $(this).data("confirm-btn");

    swal({
      title: headText,
      text: bodyText,
      icon: "warning",
      closeOnClickOutside: false,
      closeOnEsc: false,
      dangerMode: true,
      buttons: [cancelBtn, confirmBtn]
    }).then((value) => {
      if (value) {
        $(`#${relativeLink}`)[0].click();
      }
    });
  });
};

export { initSweetalert };
