const initToggleEndDate = ($experience) => {
  const $endDate = $(".user_experiences_end_date", $experience);
  const $checkbox = $(".user_experiences_current", $experience);

  if ($checkbox[0].lastChild.firstChild.checked) {
    $endDate[0].lastChild.lastChild.value = "";
    $endDate.addClass("hide");
  }

  $checkbox.change(function() {
    if (this.lastChild.firstChild.checked) {
      $endDate[0].lastChild.lastChild.value = "";
      $endDate.addClass("hide");
    } else {
      $endDate.removeClass("hide");
    }
  })
};

const initToggleEhesp = ($study) => {
  const $checkbox = $(".user_studies_ehesp_school", $study);
  const $schoolInput = $(".user_studies_school", $study)[0].lastChild.firstChild;
  const $diplomaInput = $(".user_studies_diploma", $study)[0].lastChild.firstChild;

  const boxChecked = (checkbox) => {
    $schoolInput.value = checkbox.lastChild.firstChild.dataset.ehesp;
    $schoolInput.readOnly = true;
    $diplomaInput.value = "";
    $diplomaInput.readOnly = true;
  }

  if ($checkbox[0].lastChild.firstChild.checked) {
    boxChecked($checkbox[0]);
  }

  $checkbox.change(function() {
    if (this.lastChild.firstChild.checked) {
      boxChecked(this);
    } else {
      $schoolInput.value = "";
      $schoolInput.readOnly = false;
      $diplomaInput.readOnly = false;
    }
  });
}

export { initToggleEndDate, initToggleEhesp };
