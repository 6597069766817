import "flatpickr";
import "flatpickr/dist/l10n/fr.js";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";

const initFlatpickr = ($experience) => {
  $experience.flatpickr({
    locale: "fr",
    altInput: true,
    plugins: [
      new monthSelectPlugin({
        shorthand: true,
        dateFormat: "Y-m-d",
        altFormat: "F Y"
      })
    ]
  });
}

export { initFlatpickr };
